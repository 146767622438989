<template>
  <main>
    <section class="firstTouch">
      <div class="firstTouch_container">
        <img src="@/assets/about/eye-catching-drop.png">
      </div>
    </section>
  </main>
</template>
<script>
export default {
  name: 'Top'
}
</script>
<style scoped>
main{
  font-family: "YuGothic";
  font-size: 14px;
  display: block;
}
h1{
  font-size: 24px;
  font-weight: bold;
}
h2{
  font-size: 18px;
  font-weight: bold;
}
h3{
  font-size: 20px;
  padding-bottom: 80px;
}
a{
  text-decoration: none;
}
.font_BG{
  font-family: "Brandon Grotesque";
}
.firstTouch{
  background-color: #ffffff;
  padding: 0 40px;
}
.firstTouch_container{
  padding: 500px 0 50px 0;
  text-align: right;
}
.firstTouch_container h1{
  font-size: 23pxß;
  display: inline-block;
  text-align: center;
  padding-bottom: 45px;
  line-height: 1.7;
}
.firstTouch_container img{
  width: 170px;
  padding: 0 15px 50px 0;
  object-fit: cover;
}
</style>
